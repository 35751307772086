import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import SEO from '../../components/SEO';
import Container from '../../components/Container';
import { MainLayout } from '../../components/Layout';
import { OrderDetail, LightGreenButton } from '../../components/Pricing';
import ErrorMessage from '../../components/ErrorMessage';
import { SlideUpDownAnimated } from '../../utils/animation';
import { isBrowser } from '../../utils/auth';

/**
 * @return {null}
 */
function Page({ location }) {
  useEffect(() => {
    if (isBrowser() && window.innerWidth > 768) {
      navigate('/pricing/');
    }
  }, []);

  if (!location.state) {
    if (isBrowser()) navigate('/pricing/');
    return null;
  }

  const hasProductInCart = location.state.products.length > 0;

  return (
    <MainLayout>
      <SEO title="Pricing" keywords={[`dolonline`, `dolenglish`, `pricing`, 'ielts', 'gmat', 'gre']} />
      {hasProductInCart && (
        <Container>
          {location.state.error && location.state.error.cart ? (
            <ErrorMessage>{location.state.error.cart}</ErrorMessage>
          ) : (
            <SlideUpDownAnimated hide={!hasProductInCart}>
              <OrderDetail cart={location.state.cart} />
            </SlideUpDownAnimated>
          )}
          <ActionContainer>
            <LightGreenButton
              onClick={() =>
                navigate('/pricing/payment-method/', { state: { parsedProducts: location.state.parsedProducts } })
              }
            >
              Choose payment method
            </LightGreenButton>
          </ActionContainer>
        </Container>
      )}
    </MainLayout>
  );
}

const ActionContainer = styled.div`
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

Page.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Page;
