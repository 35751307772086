/**
 * Find image by name, extract from graphql query
 * @param imgName
 * @param graphQlData result from graphql query
 * @param nodeName node name {query: {nodeNameHere: {...}}}
 * @param imgType one of ['fluid', 'fixed']
 * @returns {null|object} null if not found
 */
import { isBrowser } from './auth';

const findImageByName = (imgName, graphQlData, nodeName, imgType) => {
  const img = graphQlData[nodeName].edges.find(({ node }) => imgName === node.childImageSharp[imgType].originalName);

  return img ? img.node.childImageSharp[imgType] : null;
};

export { findImageByName };

export function getCurrency() {
  if (isBrowser() && /en/gi.test(navigator.language)) return 'USD';

  return 'VND';
}

export function formatCurrency(amount, locale = navigator.language, currencyCode = getCurrency()) {
  if (!locale || !currencyCode) return amount;

  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  });
}

export const queryParamsToObject = queryString =>
  queryString
    .substr(1)
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=');
      return { ...acc, [key]: value };
    }, {});
